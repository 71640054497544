import React from "react"

import "../styles/components/footer.scss"

import LinkLogo from "../../assets/logos/linked_icon.svg"
import SpotLogo from "../../assets/logos/spot_icon.svg"
import InstaLogo from "../../assets/logos/ig_icon.svg"
import TopArrow from "../../assets/arrow.svg"

export const Footer = () => {
  function backToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  return (
    <div className="footer">
      <div className="gridded-content">
        <div className="footer-text">
          A HUMAN-CENTERED
          <br />
          TALENT AGENCY
        </div>
        <div className="back-to-top">
          <a onClick={backToTop}>
            Top
            <img
              className="top-arrow"
              src={TopArrow}
              alt="Design Citizen on LinkedIn"
            />
          </a>
        </div>
        <div className="footer-email">
          <a href="mailto:hello@designcitizen.com">hello@designcitizen.com</a>
        </div>
        <div className="social-links">
          <a href="https://www.linkedin.com/company/design-citizen">
            <img
              className="social-icon"
              src={LinkLogo}
              alt="Design Citizen on LinkedIn"
            />
          </a>
          <a href="https://www.instagram.com/design.citizen/">
            <img
              className="social-icon"
              src={InstaLogo}
              alt="Design Citizen on Instagram"
            />
          </a>
          <a href="https://open.spotify.com/playlist/41JOrYtBCXVGFY71TRnYF9?si=2-La_GHSQua7erCyg2QZnA">
            <img
              className="social-icon"
              src={SpotLogo}
              alt="Design Citizen on Spotify"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer;

