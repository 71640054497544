import React, { useState } from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"

import "../styles/components/menu.scss"
import { Link } from "gatsby"

const variants = {
  open: {
    opacity: 1,
    y: 0,
    // transition: {
    //   ease: "easeOut",
    //   duration: 1.2,
    // },
  },
  closed: {
    opacity: 0,
    y: "60px",
    // transition: { duration: 0.1 },
  },
}

export const Menu = ({ current = "home" }) => {
  const [open, setOpen] = useState(false)

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0,
    // rootMargin: '500px 0px',
  })

  const style = { opacity: 0, y: 60 }
  const homeStyle = { position: "fixed", zIndex: 10 }

  return (
    <div id="citizen-navigation" ref={ref}>
      <motion.div
        className="desktop-navigation"
        style={current === "home" ? { ...style, ...homeStyle } : { ...style }}
        animate={inView ? "open" : "closed"}
        variants={variants}
      >
        <DesktopHeader current={current} />
      </motion.div>
      <motion.div
        className="mobile-navigation"
        style={current === "home" ? { ...style, ...homeStyle } : { ...style }}
        animate={inView ? "open" : "closed"}
        variants={variants}
      >
        <MobileHeader setOpen={setOpen} open={open} current={current} />
      </motion.div>
      {open && (
        <div className="open-nav">
          <div className="mobile-link" onClick={() => setOpen(false)}>
            <Link to="/about">Our Story</Link>
          </div>
          <div className="mobile-link" onClick={() => setOpen(false)}>
            <Link to="/community">Community</Link>
          </div>
          <div className="mobile-link" onClick={() => setOpen(false)}>
            <Link to="/coaching">Coaching</Link>
          </div>
          <div className="mobile-link" onClick={() => setOpen(false)}>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
      )}
    </div>
  )
}

Menu.propTypes = {
  current: PropTypes.string,
}

export default Menu
