import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

import Menu from "../components/Menu"
import Footer from "../components/Footer"

import "../styles/main.scss"

const getCurrentFromLocation = location => {
  if (location.pathname === withPrefix("/")) {
    return "home"
  }
  if (location.pathname === withPrefix("/about")) {
    return "about"
  }
  if (location.pathname === withPrefix("/community")) {
    return "community"
  }
  if (location.pathname === withPrefix("/coaching")) {
    return "coaching"
  }
  if (location.pathname === withPrefix("/contact")) {
    return "contact"
  }

  return "other"
}

export const Layout = ({ children, location }) => (
  <div className="citizen-wrapper">
    <Menu current={getCurrentFromLocation(location)} />
    {children}
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default Layout
